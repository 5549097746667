interface TeamConfig {
  COMPLETE_TEAMS: string[];
  QUALITY_TEAMS: string[];
}

const devConfig: TeamConfig = {
  COMPLETE_TEAMS: [
    '674823b41aaa5d2654b07046',
    '677fd998a1078b28e6698a34',
    '6790d9c62c60969416c37f66',
  ],
  QUALITY_TEAMS: ['677e6429529c156bad1753cd'],
};

const prodConfig: TeamConfig = {
  COMPLETE_TEAMS: [
    '677e7d8bfdf4de3c4e4936d4',
    '678611e247bea106224a9173',
    '67875cb1fa4ab5eef1e8b07d',
    '678a36287b54d7779ef0c60d',
  ],
  QUALITY_TEAMS: ['679357f452651c449993ae32'],
};

export const getTeamConfig = (): TeamConfig => {
  return process.env.REACT_APP_ENV === 'production' ? prodConfig : devConfig;
};
