import React, { useEffect, useState } from 'react';
import {
  Card,
  Row,
  Col,
  Statistic,
  Spin,
  Table,
  Tag,
  Typography,
  Tabs,
  Badge,
  Button,
  Tooltip,
} from 'antd';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RechartsTooltip,
  Legend,
  PieChart,
  Pie,
  Cell,
} from 'recharts';
import { getCollectionDashboardMetrics } from '../../api/dashboard.api';
import type { ColumnsType } from 'antd/es/table';
import { Link, useNavigate } from 'react-router-dom';
import type { TabsProps } from 'antd';
import Title from 'antd/es/typography/Title';
import { RoutePath } from '../../constants/routePaths';
import { InfoCircleOutlined } from '@ant-design/icons';
import {
  exportEasilyCollectibleMoneyReport,
  exportFieldCollectionCasesReport,
} from '../../api/export.api';

interface BusinessInsight {
  callId: string;
  tag: string;
  remarks: string;
  subTag?: string;
}

interface DashboardProps {
  data: {
    totalCalls: number;
    dispositions: Record<string, number>;
    totalCases: number;
    twelvePtpCount: number;
    ptpStock: number;
    businessInsights: BusinessInsight[];
    customersWithRtpOrDispute: Array<{
      _id: string;
      disposition: 'RTP' | 'DISPUTE';
      customerId: string;
      loanAmount: number;
      cId: string;
    }>;
    lastMonthDispositions: Record<string, number>;
    easilyCollectibleMoney: Array<{
      _id: string;
      customerId: string;
      cId: string;
      loanAmount: number;
      businessInsightsTag: string;
    }>;
  };
}

const { Text } = Typography;

const CollectionDashboard: React.FC<DashboardProps> = () => {
  const navigate = useNavigate();
  const [dispositionMetrics, setDispositionMetrics] = useState<DashboardProps['data'] | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isDownloading, setIsDownloading] = useState(false);

  useEffect(() => {
    getCollectionDashboardMetrics().then((data) => {
      setDispositionMetrics(data);
      setIsLoading(false);
    });
  }, []);

  // Only prepare the data if dispositionMetrics is not null
  const dispositionData = dispositionMetrics
    ? Object.entries(dispositionMetrics.dispositions).map(([name, value]) => ({
        name,
        value,
      }))
    : [];

  // Colors for pie chart
  const COLORS = [
    '#1890FF', // High PTP - Blue
    '#52C41A', // Medium PTP - Green
    '#FAAD14', // Low PTP - Yellow/Gold
    '#FF4D4F', // RTP - Red
    '#722ED1', // Callback - Purple
    '#13C2C2', // Already Paid - Cyan
    '#F759AB', // Dispute - Pink
    '#FA8C16', // NRPC - Orange
    '#8C8C8C', // Neutral - Gray
  ];

  // Group insights by tag and subtag
  const groupedInsights =
    dispositionMetrics?.businessInsights?.reduce(
      (acc, insight) => {
        if (!acc[insight.tag]) {
          acc[insight.tag] = {
            withoutSubtag: [],
            subtags: {},
          };
        }

        if (insight.subTag) {
          if (!acc[insight.tag].subtags[insight.subTag]) {
            acc[insight.tag].subtags[insight.subTag] = [];
          }
          acc[insight.tag].subtags[insight.subTag].push(insight);
        } else {
          acc[insight.tag].withoutSubtag.push(insight);
        }
        return acc;
      },
      {} as Record<
        string,
        {
          withoutSubtag: BusinessInsight[];
          subtags: Record<string, BusinessInsight[]>;
        }
      >
    ) || {};

  const insightColumns = [
    {
      title: 'Insight',
      dataIndex: 'remarks',
      key: 'remarks',
      // width: '80%',
    },
    {
      title: 'Action',
      dataIndex: 'callId',
      key: 'callId',
      width: '10%',
      render: (id: string) => {
        return <Link to={`${RoutePath.CALL_ANALYSIS}?callId=${id}`}>View Call</Link>;
      },
    },
  ];

  const getTagColor = (count: number) => {
    if (count >= 50) return '#f50'; // Red for high counts
    if (count >= 25) return '#fa8c16'; // Orange for medium counts
    return '#52c41a'; // Green for low counts
  };

  // Modified tab items creation
  const tabItems: TabsProps['items'] = Object.entries(groupedInsights)
    .sort(([, a], [, b]) => {
      const totalA = a.withoutSubtag.length + Object.values(a.subtags).flat().length;
      const totalB = b.withoutSubtag.length + Object.values(b.subtags).flat().length;
      return totalB - totalA;
    })
    .map(([tag, { withoutSubtag, subtags }]) => {
      const totalCount = withoutSubtag.length + Object.values(subtags).flat().length;

      // If there are subtags, create nested tabs
      const hasSubtags = Object.keys(subtags).length > 0;
      const content = hasSubtags ? (
        <Tabs
          items={[
            ...Object.entries(subtags).map(([subTag, insights]) => ({
              key: subTag,
              label: (
                <span>
                  {subTag
                    .split('_')
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                    .join(' ')}{' '}
                  <Tag color={getTagColor(insights.length)}>{insights.length}</Tag>
                </span>
              ),
              children: (
                <Table
                  dataSource={insights}
                  columns={insightColumns}
                  pagination={{ pageSize: 5, showSizeChanger: false }}
                  rowKey="callId"
                />
              ),
            })),
            ...(withoutSubtag.length > 0
              ? [
                  {
                    key: 'others',
                    label: (
                      <span>
                        Others{' '}
                        <Tag color={getTagColor(withoutSubtag.length)}>{withoutSubtag.length}</Tag>
                      </span>
                    ),
                    children: (
                      <Table
                        dataSource={withoutSubtag}
                        columns={insightColumns}
                        pagination={{ pageSize: 5, showSizeChanger: false }}
                        rowKey="callId"
                      />
                    ),
                  },
                ]
              : []),
          ]}
        />
      ) : (
        <Table
          dataSource={withoutSubtag}
          columns={insightColumns}
          pagination={{ pageSize: 5, showSizeChanger: false }}
          rowKey="callId"
        />
      );

      return {
        key: tag,
        label: (
          <span>
            {tag
              .split('_')
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
              .join(' ')}{' '}
            <Tag color={getTagColor(totalCount)}>{totalCount}</Tag>
          </span>
        ),
        children: content,
      };
    });

  const handlePieClick = (data: any) => {
    let formattedDisposition = data.name.toUpperCase().split(' ').join('_');

    if (['HIGH_PTP', 'MEDIUM_PTP', 'LOW_PTP'].includes(formattedDisposition)) {
      formattedDisposition = data.name
        .split(' ')
        .map((word) =>
          word.toLowerCase() === 'ptp'
            ? 'PTP'
            : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        .join(' ');
    }

    navigate(`${RoutePath.ALL_CALLS}?disposition=${formattedDisposition}`);
  };

  // Custom tooltip component
  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload[0]) {
      const currentValue = payload[0].value;
      const dispositionName = payload[0].name;
      const lastMonthValue = dispositionMetrics?.lastMonthDispositions[dispositionName] || 0;

      const percentageChange =
        lastMonthValue === 0
          ? 100 // If last month was 0, show 100% increase
          : ((currentValue - lastMonthValue) / lastMonthValue) * 100;

      return (
        <div
          style={{
            backgroundColor: 'white',
            padding: '10px',
            border: '1px solid #ccc',
            borderRadius: '4px',
          }}
        >
          <p>
            <strong>{dispositionName}</strong>
          </p>
          <p>Current: {currentValue}</p>
          <p>Last Month: {lastMonthValue}</p>
          <p
            style={{
              color: percentageChange >= 0 ? '#52c41a' : '#f5222d',
            }}
          >
            {percentageChange >= 0 ? '↑' : '↓'} {Math.abs(percentageChange).toFixed(1)}%
          </p>
        </div>
      );
    }
    return null;
  };

  const handleexportEasilyCollectibleMoneyReport = async () => {
    try {
      setIsDownloading(true);
      const { url } = await exportEasilyCollectibleMoneyReport();
      const link = document.createElement('a');
      link.href = url;
      link.download = '';
      link.click();
    } catch (error) {
      console.error('Error exporting report:', error);
    } finally {
      setIsDownloading(false);
    }
  };

  const handleExportFieldCollectionCasesReport = async () => {
    try {
      setIsDownloading(true);
      const { url } = await exportFieldCollectionCasesReport();
      const link = document.createElement('a');
      link.href = url;
      link.download = '';
      link.click();
    } catch (error) {
      console.error('Error exporting field collection cases report:', error);
    } finally {
      setIsDownloading(false);
    }
  };

  if (isLoading || !dispositionMetrics) {
    return (
      <div style={{ padding: '24px', textAlign: 'center' }}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div style={{ padding: '24px' }}>
      <div style={{ marginBottom: '24px' }}>
        <Title level={2}>Collection Analytics Dashboard</Title>
        <Row align="middle" gutter={8}>
          <Col>
            <Typography.Text type="secondary" style={{ fontSize: '16px' }}>
              Showing collection metrics for{' '}
              {new Date().toLocaleString('default', { month: 'long', year: 'numeric' })}
            </Typography.Text>
          </Col>
          <Col>
            <Tooltip title="Metrics are calculated based on collections data from the current month">
              <InfoCircleOutlined style={{ color: '#8c8c8c' }} />
            </Tooltip>
          </Col>
        </Row>
      </div>

      {isLoading ? (
        <Spin size="default" />
      ) : (
        <>
          {/* Main Row with Stats and Pie Chart */}
          <Row gutter={[24, 24]}>
            {/* Left Column - Statistics */}
            <Col span={6}>
              <Card className="metrics-card">
                <div className="stats-container">
                  <div className="stat-item">
                    <Link to={`${RoutePath.ALL_CALLS}`}>
                      <Statistic
                        title={
                          <span>
                            Total Calls This Month{' '}
                            <Tooltip title="Number of collection calls made in current month">
                              <InfoCircleOutlined style={{ fontSize: '14px', color: '#8c8c8c' }} />
                            </Tooltip>
                          </span>
                        }
                        value={dispositionMetrics.totalCalls}
                      />
                    </Link>
                  </div>
                  <div className="stat-item">
                    <Link to={`${RoutePath.ALL_CUSTOMERS}`}>
                      <Statistic
                        title={
                          <span>
                            Active Cases{' '}
                            <Tooltip title="Number of active collection cases">
                              <InfoCircleOutlined style={{ fontSize: '14px', color: '#8c8c8c' }} />
                            </Tooltip>
                          </span>
                        }
                        value={dispositionMetrics.totalCases}
                      />
                    </Link>
                  </div>
                  <div className="stat-item">
                    <Statistic
                      title={
                        <span>
                          Current PTP Stock{' '}
                          <Tooltip title="Total value of Promise to Pay commitments for current month">
                            <InfoCircleOutlined style={{ fontSize: '14px', color: '#8c8c8c' }} />
                          </Tooltip>
                        </span>
                      }
                      value={dispositionMetrics.ptpStock}
                      prefix="₹"
                      precision={0}
                    />
                  </div>
                  <div className="stat-item">
                    <Statistic
                      title={
                        <span>
                          High Confidence Calls{' '}
                          <Tooltip title="Number of calls with high confidence PTP commitments">
                            <InfoCircleOutlined style={{ fontSize: '14px', color: '#8c8c8c' }} />
                          </Tooltip>
                        </span>
                      }
                      value={dispositionMetrics.twelvePtpCount}
                    />
                  </div>
                </div>
              </Card>
            </Col>

            {/* Right Column - Pie Chart */}
            <Col span={18}>
              <Card
                title={
                  <span>
                    Call Dispositions (Current Month){' '}
                    <Tooltip title="Distribution of call outcomes for the current month">
                      <InfoCircleOutlined style={{ fontSize: '14px', color: '#8c8c8c' }} />
                    </Tooltip>
                  </span>
                }
                style={{ height: '500px' }}
              >
                <div className="chart-container">
                  <PieChart width={700} height={400}>
                    <Pie
                      data={dispositionData}
                      cx={250}
                      cy={200}
                      innerRadius={90}
                      outerRadius={150}
                      paddingAngle={2}
                      dataKey="value"
                      style={{ cursor: 'pointer' }}
                      onClick={handlePieClick}
                      label={({ cx, cy, midAngle, innerRadius, outerRadius, value, index }) => {
                        const RADIAN = Math.PI / 180;
                        const radius = outerRadius + 35;
                        const x = cx + radius * Math.cos(-midAngle * RADIAN);
                        const y = cy + radius * Math.sin(-midAngle * RADIAN);

                        return (
                          <text
                            x={x}
                            y={y}
                            fill={COLORS[index]}
                            textAnchor={x > cx ? 'start' : 'end'}
                            dominantBaseline="central"
                          >
                            {value}
                          </text>
                        );
                      }}
                      labelLine={({ cx, cy, midAngle, outerRadius, index }) => {
                        const RADIAN = Math.PI / 180;
                        const radius = outerRadius + 30;
                        const x1 = cx + outerRadius * Math.cos(-midAngle * RADIAN);
                        const y1 = cy + outerRadius * Math.sin(-midAngle * RADIAN);
                        const x2 = cx + radius * Math.cos(-midAngle * RADIAN);
                        const y2 = cy + radius * Math.sin(-midAngle * RADIAN);

                        return (
                          <path d={`M${x1},${y1}L${x2},${y2}`} stroke={COLORS[index]} fill="none" />
                        );
                      }}
                    >
                      {dispositionData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index]} />
                      ))}
                    </Pie>
                    <Legend
                      layout="vertical"
                      align="right"
                      verticalAlign="middle"
                      iconType="circle"
                      formatter={(value) => value}
                      wrapperStyle={{
                        paddingLeft: '40px',
                      }}
                    />
                    <RechartsTooltip content={<CustomTooltip />} />
                  </PieChart>
                </div>
              </Card>
            </Col>
          </Row>

          {/* Tables Row */}
          <Row gutter={[24, 24]} style={{ marginTop: '24px' }}>
            {/* Easily Collectible Money */}
            <Col span={12}>
              <Card
                title={
                  <span>
                    Easily Collectible Money{' '}
                    <Tooltip title="Cases identified as high probability for collection this month">
                      <InfoCircleOutlined style={{ fontSize: '14px', color: '#8c8c8c' }} />
                    </Tooltip>
                  </span>
                }
                style={{ height: '500px' }}
                extra={
                  <Button
                    type="default"
                    onClick={handleexportEasilyCollectibleMoneyReport}
                    loading={isDownloading}
                  >
                    Download Report
                  </Button>
                }
              >
                <Table
                  dataSource={dispositionMetrics.easilyCollectibleMoney.sort(
                    (a, b) => b.loanAmount - a.loanAmount
                  )}
                  pagination={{
                    pageSize: 5,
                    showSizeChanger: false,
                    showTotal: (total) => `Total ${total} customers`,
                  }}
                  columns={[
                    {
                      title: 'Customer ID',
                      dataIndex: 'cId',
                      key: 'cId',
                      render: (text: string, record) => (
                        <Link
                          to={`${RoutePath.CUSTOMER_DETAILS}?customerId=${record.customerId}`}
                          style={{ color: 'black' }}
                        >
                          {text}
                        </Link>
                      ),
                    },
                    {
                      title: 'Loan Amount',
                      dataIndex: 'loanAmount',
                      key: 'loanAmount',
                      render: (amount: number) => (
                        <Text strong style={{ color: '#52C41A' }}>
                          ₹{(amount ?? 0).toLocaleString()}
                        </Text>
                      ),
                    },
                    {
                      title: 'Tag',
                      dataIndex: 'businessInsightsTag',
                      key: 'businessInsightsTag',
                      render: (tag: string) => (
                        <Tag color="blue">
                          {tag
                            .split('_')
                            .map((word) => word.charAt(0) + word.slice(1).toLowerCase())
                            .join(' ')}
                        </Tag>
                      ),
                    },
                  ]}
                />
              </Card>
            </Col>

            {/* Field Collections */}
            <Col span={12}>
              <Card
                title={
                  <span>
                    Field Collection Cases{' '}
                    <Tooltip title="Cases recommended for field collection based on current month's interactions">
                      <InfoCircleOutlined style={{ fontSize: '14px', color: '#8c8c8c' }} />
                    </Tooltip>
                  </span>
                }
                style={{ height: '500px' }}
                extra={
                  <Button
                    type="default"
                    onClick={handleExportFieldCollectionCasesReport}
                    loading={isDownloading}
                  >
                    Download Report
                  </Button>
                }
              >
                <Table
                  dataSource={dispositionMetrics.customersWithRtpOrDispute}
                  pagination={{
                    pageSize: 5,
                    showSizeChanger: false,
                    showTotal: (total) => `Total ${total} cases`,
                  }}
                  columns={[
                    {
                      title: 'Customer ID',
                      dataIndex: 'cId',
                      key: 'cId',
                      render: (text: string, record) => (
                        <Link
                          to={`${RoutePath.CUSTOMER_DETAILS}?customerId=${record.customerId}`}
                          style={{ color: 'black' }}
                        >
                          {text}
                        </Link>
                      ),
                    },
                    {
                      title: 'Loan Amount',
                      dataIndex: 'loanAmount',
                      key: 'loanAmount',
                      render: (amount: number) => <Text>₹{(amount ?? 0).toLocaleString()}</Text>,
                    },
                    {
                      title: 'Status',
                      dataIndex: 'disposition',
                      key: 'disposition',
                      render: (disposition: string) => (
                        <Tag
                          color={
                            disposition === 'RTP'
                              ? 'red'
                              : disposition === 'DISPUTE'
                                ? 'orange'
                                : 'cyan'
                          }
                        >
                          {disposition === 'RTP'
                            ? 'RTP'
                            : disposition === 'DISPUTE'
                              ? 'Dispute'
                              : 'Low PTP'}
                        </Tag>
                      ),
                    },
                  ]}
                />
              </Card>
            </Col>
          </Row>

          {/* Business Insights */}
          <Row style={{ marginTop: '24px' }}>
            <Col span={24}>
              <Card
                title={
                  <span>
                    Business Insights{' '}
                    <Tooltip title="Key insights derived from current month's collection activities">
                      <InfoCircleOutlined style={{ fontSize: '14px', color: '#8c8c8c' }} />
                    </Tooltip>
                  </span>
                }
              >
                <Tabs items={tabItems} defaultActiveKey={Object.keys(groupedInsights)[0]} />
              </Card>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default CollectionDashboard;
