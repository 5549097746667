import { Content } from 'antd/es/layout/layout';
import { useEffect, useState } from 'react';
import { getAllExcelFiles } from '../../api/call.api';
import { Spin, Table, Progress } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Link } from 'react-router-dom';
import { RoutePath } from '../../constants/routePaths';
import Title from 'antd/es/typography/Title';

interface ExcelFileItem {
  fileKey: string;
  urlCount: number;
  timestamp: number;
  processingStatus: {
    processedCount: number;
    failedItems: any[];
  };
}

const ExcelUploads = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [uploadedExcelFiles, setUploadedExcelFiles] = useState<ExcelFileItem[]>([]);

  useEffect(() => {
    getAllExcelFiles().then((data: any[]) => {
      setUploadedExcelFiles(
        data.map((d) => ({
          fileKey: d.fileKey,
          urlCount: d.urlCount,
          timestamp: d.timestamp,
          processingStatus: {
            processedCount: d.processingStatus.processedCount,
            failedItems: d.processingStatus.failedItems,
          },
        }))
      );
      setIsLoading(false);
    });
  }, []);

  const columns: ColumnsType<ExcelFileItem> = [
    {
      title: 'File',
      dataIndex: 'fileKey',
      key: 'fileKey',
      render: (fileKey: string) => {
        const fileName = fileKey.split('/')[1];
        return <span>{`${fileName.split('@')[0]}.${fileName.split('.')[1]}`}</span>;
      },
      width: '30%',
    },
    {
      title: 'URLs',
      dataIndex: 'urlCount',
      key: 'urlCount',
      width: '20%',
    },
    {
      title: 'Date and Time',
      dataIndex: 'timestamp',
      key: 'timestamp',
      render: (timestamp: string) => <span>{new Date(timestamp).toLocaleString()}</span>,
      width: '20%',
    },
    {
      title: 'Upload Progress',
      key: 'processingStatus',
      render: (status: ExcelFileItem) => {
        const processedCount = status.processingStatus?.processedCount || 0;
        const urlCount = status.urlCount || 1;
        const percentage = ((processedCount / urlCount) * 100).toFixed(2);
        return <Progress percent={parseFloat(percentage)} />;
      },
      width: '20%',
    },
    {
      dataIndex: 'fileKey',
      key: 'fileKey',
      render: (fileKey: string, record: ExcelFileItem) => {
        const isViewEnabled =
          record.urlCount ===
          (record.processingStatus?.processedCount || 0) +
            (record.processingStatus?.failedItems.length || 0);
        const params = new URLSearchParams({ fileKey });
        if (isViewEnabled) {
          return <Link to={`${RoutePath.EXCEL_CALLS}?${params.toString()}`}>View Calls</Link>;
        }
        return null;
      },
    },
  ];

  return (
    <Content className="main-content">
      <Title level={2}>Excel Uploads</Title>
      {isLoading ? (
        <Spin size="default" />
      ) : (
        <Table columns={columns} dataSource={uploadedExcelFiles} rowKey="id" />
      )}
    </Content>
  );
};

export default ExcelUploads;
