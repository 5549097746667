import React, { useEffect, useState } from 'react';
import { Avatar, Button, Card, Dropdown, Layout, Menu, MenuProps } from 'antd';
import {
  DollarOutlined,
  TeamOutlined,
  PhoneOutlined,
  UploadOutlined,
  FileExcelOutlined,
  CaretDownOutlined,
  SafetyOutlined,
  AuditOutlined,
} from '@ant-design/icons';
import './Sidebar.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { RoutePath } from '../../constants/routePaths';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { clearUser } from '../../redux/slices/user';
import { COMPLETE_TEAMS } from '../../config/teamPermissions';

const { Sider } = Layout;

const Sidebar: React.FC = () => {
  const [selectedKey, setSelectedKey] = useState('1');
  const location = useLocation();
  const navigate = useNavigate();

  const { name, photoUrl, teamId } = useSelector((state: RootState) => state.persisted.user.value);
  const dispatch = useDispatch();

  const LogoutButton = () => {
    return (
      <Button
        className="logout-button"
        onClick={() => {
          dispatch(clearUser());
          navigate(RoutePath.HOME);
        }}
      >
        <span>Logout</span>
      </Button>
    );
  };

  const userMenuItems: MenuProps['items'] = [
    {
      label: <LogoutButton />,
      key: '0',
    },
  ];

  useEffect(() => {
    switch (location.pathname) {
      case RoutePath.COLLECTIONS_DASHBOARD:
        setSelectedKey('1');
        break;
      case RoutePath.AGENT_DASHBOARD:
        setSelectedKey('2');
        break;
      case RoutePath.COMPLIANCE_DASHBOARD:
        setSelectedKey('3');
        break;
      case RoutePath.ALL_CALLS:
        setSelectedKey('4');
        break;
      case RoutePath.ALL_AGENTS:
        setSelectedKey('5');
        break;
      case RoutePath.UPLOAD_CALL:
        setSelectedKey('6');
        break;
      case RoutePath.EXCEL_UPLOADS:
        setSelectedKey('7');
        break;
      default:
        setSelectedKey(null);
    }
  }, [location]);

  const menuItems = [
    {
      key: '1',
      icon: <DollarOutlined />,
      label: <Link to={RoutePath.COLLECTIONS_DASHBOARD}>Collections</Link>,
      access: COMPLETE_TEAMS.includes(teamId),
    },
    {
      key: '2',
      icon: <AuditOutlined />,
      label: <Link to={RoutePath.AGENT_DASHBOARD}>Agent QA</Link>,
      access: true,
    },
    {
      key: '3',
      icon: <SafetyOutlined />,
      label: <Link to={RoutePath.COMPLIANCE_DASHBOARD}>Compliance</Link>,
      access: true,
    },
    {
      key: '4',
      icon: <PhoneOutlined />,
      label: <Link to={RoutePath.ALL_CALLS}>All Calls</Link>,
      access: true,
    },
    {
      key: '5',
      icon: <TeamOutlined />,
      label: <Link to={RoutePath.ALL_AGENTS}>All Agents</Link>,
      access: true,
    },
    {
      key: '6',
      icon: <UploadOutlined />,
      label: <Link to={RoutePath.UPLOAD_CALL}>Upload Calls</Link>,
      access: true,
    },
    {
      key: '7',
      icon: <FileExcelOutlined />,
      label: <Link to={RoutePath.EXCEL_UPLOADS}>Excel Uploads</Link>,
      access: true,
    },
  ];

  return (
    <Sider width={220} className="sidebar" style={{ background: '#fff', position: 'fixed' }}>
      <div className="logo">
        <img
          src="https://framerusercontent.com/images/AMhqStmgeNogZUvXHJYVJD7osc.png"
          alt="Logo"
          style={{ width: '150px', marginBottom: '30px' }}
        />
      </div>
      <div className="sidebar-content">
        <Menu mode="inline" selectedKeys={[selectedKey]} style={{ borderRight: 0 }}>
          {menuItems
            .filter((item) => item.access)
            .map((item) => (
              <Menu.Item key={item.key} icon={item.icon}>
                {item.label}
              </Menu.Item>
            ))}
        </Menu>
        <div className="user-section">
          <Dropdown menu={{ items: userMenuItems }} trigger={['click']}>
            <div className="user-card">
              <Avatar src={photoUrl} />
              <div style={{ paddingLeft: '15px', alignSelf: 'center' }}>
                {name.split(' ')[0]} &nbsp;
                <CaretDownOutlined />
              </div>
            </div>
          </Dropdown>
          {/* <a href="https://recontact.world" style={{ marginTop: "10px" }}>
              <img
                width="150"
                src="https://s3.amazonaws.com/recontact.world/powered-by-recontact-dark.svg"
                alt="Powered by Recontact"
              />
            </a> */}
        </div>
      </div>
    </Sider>
  );
};

export default Sidebar;
