import React, { useEffect, useState } from 'react';
import { Table, Tag, Spin, DatePicker, Button } from 'antd';
import { ColumnsType, TableProps } from 'antd/es/table';
import './AllCalls.css';
import { Link } from 'react-router-dom';
import { getAllCalls } from '../../api/call.api';
import { RoutePath } from '../../constants/routePaths';
import Title from 'antd/es/typography/Title';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { COMPLETE_TEAMS } from '../../config/teamPermissions';

const { RangePicker } = DatePicker;

interface CallData {
  id: string;
  key: string;
  timestamp: string;
  duration: number;
  score: string;
  status: 'completed' | 'processing' | 'waiting' | 'failed';
  disposition: string;
}

const AllCalls: React.FC = () => {
  const [data, setData] = useState<CallData[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pickerValue, setPickerValue] = useState<[string, string] | null>(null);
  const [filteredInfo, setFilteredInfo] = useState<Record<string, any>>({});
  const { teamId } = useSelector((state: RootState) => state.persisted.user.value);

  // Get disposition from URL parameters
  const searchParams = new URLSearchParams(window.location.search);
  const dispositionFilter = searchParams.get('disposition');

  console.log('dispositionFilter', dispositionFilter);

  useEffect(() => {
    getAllCalls().then((data) => {
      const calls = data.calls.map((call: any) => ({
        id: call._id,
        key: call.key,
        timestamp: new Date(call.timestamp ?? Date.now()).toLocaleString(),
        duration: call.duration,
        score:
          call.callScore && call.totalScore && call.totalScore !== 0
            ? `${Math.round((call.callScore / call.totalScore) * 10000) / 100}%`
            : '-',
        status: call.processStatus,
        disposition: call.disposition,
      }));
      setData(
        calls.sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime())
      );

      // If there's a disposition filter in the URL, apply it
      if (dispositionFilter) {
        setFilteredInfo({ disposition: [dispositionFilter] });
      }

      setIsLoading(false);
    });
  }, [dispositionFilter]);

  const handleChange: TableProps<CallData>['onChange'] = (pagination, filters) => {
    setFilteredInfo(filters);
  };

  const getColumns = (): ColumnsType<CallData> => {
    const baseColumns: ColumnsType<CallData> = [
      {
        title: 'Call',
        dataIndex: 'key',
        key: 'key',
        render: (key: string) => <span>{key.split('/')[1]}</span>,
        width: '30%',
      },
      {
        title: 'Agent Score',
        dataIndex: 'score',
        key: 'score',
        width: '10%',
        sorter: (a, b) => {
          const scoreA = a.score === '-' ? -1 : parseFloat(a.score);
          const scoreB = b.score === '-' ? -1 : parseFloat(b.score);
          return scoreA - scoreB;
        },
      },
    ];

    // Only add disposition column for complete teams
    if (COMPLETE_TEAMS.includes(teamId)) {
      baseColumns.push({
        title: 'Disposition',
        dataIndex: 'disposition',
        key: 'disposition',
        width: '10%',
        filteredValue: filteredInfo.disposition || null,
        render: (disposition: string) => {
          const colorMap = {
            'High PTP': 'green',
            'Medium PTP': 'lime',
            'Low PTP': 'cyan',
            RTP: 'red',
            CALLBACK: 'blue',
            ALREADY_PAID: 'purple',
            DISPUTE: 'orange',
            NRPC: 'volcano',
            NEUTRAL: 'default',
          };

          const displayText: Record<string, string> = {
            HIGH_PTP: 'High PTP',
            MEDIUM_PTP: 'Medium PTP',
            LOW_PTP: 'Low PTP',
            RTP: 'RTP',
            CALLBACK: 'Callback',
            ALREADY_PAID: 'Already Paid',
            DISPUTE: 'Dispute',
            NRPC: 'NRPC',
            NEUTRAL: 'Neutral',
          };

          return (
            <Tag color={colorMap[disposition as keyof typeof colorMap]}>
              {displayText[disposition] || disposition}
            </Tag>
          );
        },
        filters: [
          { text: 'High PTP', value: 'High PTP' },
          { text: 'Medium PTP', value: 'Medium PTP' },
          { text: 'Low PTP', value: 'Low PTP' },
          { text: 'RTP', value: 'RTP' },
          { text: 'Callback', value: 'CALLBACK' },
          { text: 'Already Paid', value: 'ALREADY_PAID' },
          { text: 'Dispute', value: 'DISPUTE' },
          { text: 'NRPC', value: 'NRPC' },
          { text: 'Neutral', value: 'NEUTRAL' },
        ],
        onFilter: (value, record) => record.disposition === value,
      });
    }

    // Add remaining columns
    baseColumns.push(
      {
        title: 'Call Date & Time',
        dataIndex: 'timestamp',
        key: 'timestamp',
        width: '20%',
        sorter: (a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime(),
        filterDropdown: ({ setSelectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <RangePicker
              onChange={(dates, dateStrings) => {
                if (dates) {
                  setPickerValue([dateStrings[0], dateStrings[1]]); // Set range
                  setSelectedKeys(dateStrings); // Set selected keys
                } else {
                  setPickerValue(null); // Clear range
                  setSelectedKeys([]);
                }
              }}
              style={{ marginBottom: 8, display: 'block' }}
            />
            <Button
              type="link"
              onClick={() => {
                setPickerValue(null); // Clear picker value
                clearFilters && clearFilters();
                confirm();
              }}
              style={{ marginRight: 8 }}
            >
              Reset
            </Button>
            <Button
              type="primary"
              onClick={() => {
                confirm();
              }}
            >
              Filter
            </Button>
          </div>
        ),
        onFilter: (value, record) => {
          if (pickerValue) {
            const [startDate, endDate] = pickerValue;
            const recordDate = new Date(record.timestamp).toISOString();
            return (
              recordDate >= new Date(startDate).toISOString() &&
              recordDate <= new Date(endDate).toISOString()
            );
          }
          return false;
        },
      },
      {
        title: 'Processing Status',
        dataIndex: 'status',
        key: 'status',
        width: '15%',
        render: (status: string) => {
          const statusColors: Record<string, string> = {
            completed: 'green',
            processing: 'gold',
            waiting: 'blue',
            failed: 'red',
          };
          return <Tag color={statusColors[status]}>{status}</Tag>;
        },
        filters: [
          { text: 'Completed', value: 'completed' },
          { text: 'Processing', value: 'processing' },
          { text: 'Waiting', value: 'waiting' },
          { text: 'Failed', value: 'failed' },
        ],
        onFilter: (value, record) => record.status === value,
      },
      {
        dataIndex: 'id',
        key: 'id',
        width: '10%',
        render: (id: string, record: CallData) => {
          if (record.status === 'completed') {
            return <Link to={`${RoutePath.CALL_ANALYSIS}?callId=${id}`}>View Call</Link>;
          } else {
            return <span></span>;
          }
        },
      }
    );

    return baseColumns;
  };

  return (
    <div className="main-content">
      <Title level={2}>All Calls</Title>
      {isLoading ? (
        <Spin size="default" />
      ) : (
        <Table columns={getColumns()} dataSource={data} rowKey="id" onChange={handleChange} />
      )}
    </div>
  );
};

export default AllCalls;
