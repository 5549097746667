import { callApi } from '.';

export const getAllCustomers = async () => {
  return await callApi({
    method: 'GET',
    url: '/customer/all',
    serializerFunc: (response: any) => response.data,
  });
};

export const getCustomerCalls = async ({ customerId }: { customerId: string }) => {
  const params = new URLSearchParams({ customerId });
  return await callApi({
    method: 'GET',
    url: `/customer/calls?${params.toString()}`,
    serializerFunc: (response: any) => response.data,
  });
};
