import { callApi } from '.';

// export const getAllAgents = async () => {
//   return await callApi({
//     method: "GET",
//     url: "/agent/all",
//     serializerFunc: (response: any) => response.data,
//   });
// };

export const authenticateUser = async ({ googleToken }) => {
  return await callApi({
    method: 'POST',
    url: '/auth/login',
    requestBody: { idToken: googleToken },
    serializerFunc: (response: any) => response.data,
  });
};
