import axios, { AxiosRequestConfig } from 'axios';
import { store } from '../redux/store';
import { clearUser } from '../redux/slices/user';

export const callApi = async ({
  method,
  url,
  requestBody = {},
  params = {},
  serializerFunc,
}: any) => {
  try {
    const state = store.getState();
    const userToken = state.persisted.user.value.token;
    const requestConfig: AxiosRequestConfig = {
      method,
      url,
      data: requestBody,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': process.env.REACT_APP_API_KEY,
        Authorization: `Bearer ${userToken}`,
      },
      params,
      baseURL: process.env.REACT_APP_API_URL,
      timeout: 1200000,
    };
    const responseData = await axios.request(requestConfig);
    const serializedRes = await serializerFunc(responseData);
    return serializedRes;
  } catch (error: any) {
    if (error.response && error.response.status === 403) {
      store.dispatch(clearUser());
      window.location.href = '/';
    } else throw new Error(error.message);
  }
};
