import { Layout } from 'antd';
import Sidebar from './components/Sidebar';
import UploadCalls from './components/UploadCalls';
import CallAnalysis from './components/CallAnalysis';
import AllCalls from './components/AllCalls';
import ProtectedRoute from './components/ProtectedRoute';
import GoogleOAuth from './components/GoogleOAuth';
import ExcelUploads from './components/ExcelUploads';
import ExcelCalls from './components/ExcelCalls';
import CustomerJourney from './components/CustomerJourney';
import ComplianceDashboard from './components/ComplianceDashboard';
import AgentDashboard from './components/AgentDashboard';
import CollectionDashboard from './components/CollectionDashboard';
import AllAgents from './components/AllAgents';
import AgentDetails from './components/AgentDetails';
import AllCustomers from './components/AllCustomers';
import { RoutePath } from './constants/routePaths';

export type RouteConfig = {
  path: string;
  element: React.ReactNode;
};

interface PageLayoutProps {
  component: React.ComponentType;
}

// eslint-disable-next-line react/prop-types
const PageLayout: React.FC<PageLayoutProps> = ({ component: Component }) => (
  <Layout style={{ minHeight: '100vh' }}>
    <Sidebar />
    <Layout style={{ marginInlineStart: 220 }}>
      <Component />
    </Layout>
  </Layout>
);

export const routes: RouteConfig[] = [
  { path: RoutePath.HOME, element: <GoogleOAuth /> },
  {
    path: RoutePath.COLLECTIONS_DASHBOARD,
    element: (
      <ProtectedRoute
        element={<PageLayout component={CollectionDashboard} />}
        path={RoutePath.COLLECTIONS_DASHBOARD}
      />
    ),
  },
  {
    path: RoutePath.AGENT_DASHBOARD,
    element: (
      <ProtectedRoute
        element={<PageLayout component={AgentDashboard} />}
        path={RoutePath.AGENT_DASHBOARD}
      />
    ),
  },
  {
    path: RoutePath.ALL_CALLS,
    element: (
      <ProtectedRoute element={<PageLayout component={AllCalls} />} path={RoutePath.ALL_CALLS} />
    ),
  },
  {
    path: RoutePath.ALL_AGENTS,
    element: (
      <ProtectedRoute element={<PageLayout component={AllAgents} />} path={RoutePath.ALL_AGENTS} />
    ),
  },
  {
    path: RoutePath.ALL_CUSTOMERS,
    element: (
      <ProtectedRoute
        path={RoutePath.ALL_CUSTOMERS}
        element={<PageLayout component={AllCustomers} />}
      />
    ),
  },
  {
    path: RoutePath.AGENT_DETAILS,
    element: (
      <ProtectedRoute
        path={RoutePath.AGENT_DETAILS}
        element={<PageLayout component={AgentDetails} />}
      />
    ),
  },
  {
    path: RoutePath.EXCEL_CALLS,
    element: (
      <ProtectedRoute
        path={RoutePath.EXCEL_CALLS}
        element={<PageLayout component={ExcelCalls} />}
      />
    ),
  },
  {
    path: RoutePath.COMPLIANCE_DASHBOARD,
    element: (
      <ProtectedRoute
        path={RoutePath.COMPLIANCE_DASHBOARD}
        element={<PageLayout component={ComplianceDashboard} />}
      />
    ),
  },
  {
    path: RoutePath.CUSTOMER_DETAILS,
    element: (
      <ProtectedRoute
        path={RoutePath.CUSTOMER_DETAILS}
        element={<PageLayout component={CustomerJourney} />}
      />
    ),
  },
  {
    path: RoutePath.EXCEL_UPLOADS,
    element: (
      <ProtectedRoute
        path={RoutePath.EXCEL_UPLOADS}
        element={<PageLayout component={ExcelUploads} />}
      />
    ),
  },
  {
    path: RoutePath.UPLOAD_CALL,
    element: (
      <ProtectedRoute
        path={RoutePath.UPLOAD_CALL}
        element={<PageLayout component={UploadCalls} />}
      />
    ),
  },

  {
    path: RoutePath.CALL_ANALYSIS,
    element: (
      <ProtectedRoute
        path={RoutePath.CALL_ANALYSIS}
        element={<PageLayout component={CallAnalysis} />}
      />
    ),
  },
  {
    path: RoutePath.NOT_FOUND,
    element: (
      <ProtectedRoute
        path={RoutePath.NOT_FOUND}
        element={<PageLayout component={() => <h2>Not Found</h2>} />}
      />
    ),
  },
];
