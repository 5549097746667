import React, { useEffect, useState } from 'react';
import { Table, Card, Row, Col, Statistic, Tag, Typography, Input, Spin } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { Link } from 'react-router-dom';
import { RoutePath } from '../../constants/routePaths';
import { getAllCustomers } from '../../api/customer.api';

const { Title, Text } = Typography;
const { Search } = Input;

interface CustomerData {
  _id: string;
  cId: string;
  loanAmount: string;
  totalCalls: number;
  ptpPercentage: number;
}

const AllCustomers = () => {
  const [searchText, setSearchText] = useState('');
  const [customerData, setCustomerData] = useState<CustomerData[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getAllCustomers().then((data) => {
      setCustomerData(data.customers);
      setIsLoading(false);
    });
  }, []);

  // Calculate summary metrics
  const summaryMetrics = {
    totalCustomers: customerData?.length || 0,
    averageLoanAmount: customerData?.length
      ? Math.round(
          customerData.reduce((acc, curr) => acc + parseInt(curr.loanAmount), 0) /
            customerData.length
        )
      : 0,
    highPerformers: customerData?.filter((customer) => customer.ptpPercentage === 100)?.length || 0,
    totalPortfolio: customerData?.length
      ? customerData.reduce((acc, curr) => acc + parseInt(curr.loanAmount), 0)
      : 0,
  };

  const columns: ColumnsType<CustomerData> = [
    {
      title: 'Customer ID',
      dataIndex: 'cId',
      key: 'cId',
      render: (text: string, record) => (
        <Link to={`${RoutePath.CUSTOMER_DETAILS}?customerId=${record._id}`}>{text}</Link>
      ),
      filteredValue: [searchText],
      onFilter: (value, record) =>
        record.cId.toLowerCase().includes(value.toString().toLowerCase()),
    },
    {
      title: 'Loan Amount',
      dataIndex: 'loanAmount',
      key: 'loanAmount',
      render: (amount: string) => <Text>₹{parseInt(amount).toLocaleString()}</Text>,
      sorter: (a, b) => parseInt(a.loanAmount) - parseInt(b.loanAmount),
    },
    {
      title: 'Total Calls',
      dataIndex: 'totalCalls',
      key: 'totalCalls',
      sorter: (a, b) => a.totalCalls - b.totalCalls,
    },
    {
      title: 'PTP Success Rate',
      dataIndex: 'ptpPercentage',
      key: 'ptpPercentage',
      render: (percentage: number) => {
        const color =
          percentage === 100
            ? 'green'
            : percentage >= 75
              ? 'blue'
              : percentage >= 50
                ? 'orange'
                : 'red';
        return <Tag color={color}>{percentage}%</Tag>;
      },
      sorter: (a, b) => a.ptpPercentage - b.ptpPercentage,
    },
  ];

  return (
    <div style={{ padding: '24px' }}>
      <Title level={2}>All Customers</Title>

      {isLoading ? (
        <Spin size="large" />
      ) : (
        <Card>
          <Search
            placeholder="Search by Customer ID"
            allowClear
            onChange={(e) => setSearchText(e.target.value)}
            style={{ marginBottom: '16px', maxWidth: '300px' }}
          />

          <Table
            columns={columns}
            dataSource={customerData}
            rowKey="_id"
            pagination={{
              pageSize: 10,
              showSizeChanger: true,
              showTotal: (total) => `Total ${total} customers`,
            }}
          />
        </Card>
      )}
    </div>
  );
};

export default AllCustomers;
