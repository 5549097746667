export enum RoutePath {
  HOME = '/',
  COLLECTIONS_DASHBOARD = '/collections-dashboard',
  AGENT_DASHBOARD = '/agent-dashboard',
  COMPLIANCE_DASHBOARD = '/compliance-dashboard',
  ALL_CALLS = '/calls/all',
  ALL_AGENTS = '/agents/all',
  ALL_CUSTOMERS = '/customers/all',
  AGENT_DETAILS = '/agent',
  UPLOAD_CALL = '/upload',
  EXCEL_UPLOADS = '/excel-uploads',
  CALL_ANALYSIS = '/call-analysis',
  EXCEL_CALLS = '/excel-calls',
  CUSTOMER_DETAILS = '/customer',
  NOT_FOUND = '*',
}
