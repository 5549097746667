/* App.js */
import React, { useEffect } from 'react';
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import { AppDispatch, RootState } from '../../redux/store';
import { setUser, setUserToken, setUserTeamId } from '../../redux/slices/user';
import { RoutePath } from '../../constants/routePaths';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { authenticateUser } from '../../api/auth.api';
import { COMPLETE_TEAMS } from '../../config/teamPermissions';
// import { handleFirstLoad } from '../../utils/firstLoad';

const GoogleOAuth = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const userToken = useSelector((state: RootState) => state.persisted.user.value.token);
  const teamId = useSelector((state: RootState) => state.persisted.user.value.teamId);

  useEffect(() => {
    // Handle first load cleanup
    // handleFirstLoad();

    if (userToken) {
      if (COMPLETE_TEAMS.includes(teamId)) {
        navigate(RoutePath.COLLECTIONS_DASHBOARD);
      } else {
        navigate(RoutePath.AGENT_DASHBOARD);
      }
    }
  }, [userToken, teamId, navigate]);

  const login = useGoogleLogin({
    onSuccess: async (response) => {
      const googleToken = response.access_token;
      toast.loading('Signing you in...');

      try {
        const { message, user } = await authenticateUser({ googleToken });
        dispatch(setUserTeamId(user.teamId));
        dispatch(setUserToken(user.token));
        dispatch(
          setUser({
            _id: user._id,
            name: user.name,
            email: user.email,
            photoUrl: user.photoUrl,
            token: user.token,
            teamId: user.teamId,
          })
        );

        toast.dismiss();
        toast.success('Signed in successfully!');

        // // Navigate based on team type
        // if (COMPLETE_TEAMS.includes(user.teamId)) {
        //   navigate(RoutePath.COLLECTIONS_DASHBOARD);
        // } else {
        //   navigate(RoutePath.AGENT_DASHBOARD);
        // }
      } catch (error) {
        console.error('Error authenticating with backend:', error);
        toast.error('Authentication failed');
      }
    },
    onError: (error) => {
      console.log('Login Failed:', error);
      toast.error('Google login failed');
    },
  });

  const logOut = () => {
    googleLogout();
    dispatch(setUserToken(''));
    toast.success('Logged out successfully!');
  };

  const GoogleSignInButton = () => {
    return (
      <button
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#FFFFFF',
          color: 'black',
          fontSize: '16px',
          fontWeight: 'bold',
          padding: '10px 20px',
          border: 'none',
          borderRadius: '4px',
          cursor: 'pointer',
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
        }}
        onClick={() => login()}
      >
        <img
          src="https://upload.wikimedia.org/wikipedia/commons/c/c1/Google_%22G%22_logo.svg"
          alt="Google logo"
          style={{ width: '18px', height: '18px', marginRight: '10px' }}
        />
        Sign in with Google
      </button>
    );
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxWidth: '100vw',
        backgroundColor: '#000',
        color: '#fff',
        height: '100vh',
        overflowX: 'hidden',
      }}
    >
      <br />
      {userToken ? (
        <div>
          <h2>Sign out of Recontact</h2>
          <button
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: '#FFFFFF',
              color: 'black',
              fontSize: '16px',
              fontWeight: 'bold',
              padding: '10px 20px',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
            }}
            onClick={logOut}
          >
            Log out
          </button>
        </div>
      ) : (
        <div>
          <h2>Sign in to Recontact</h2>
          <GoogleSignInButton />
        </div>
      )}
    </div>
  );
};

export default GoogleOAuth;
