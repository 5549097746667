import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { RootState } from '../../redux/store';
import { hasAccess } from '../../config/teamPermissions';
import { RoutePath } from '../../constants/routePaths';

interface ProtectedRouteProps {
  element: React.ReactElement;
  path: string;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element, path }) => {
  const { token, teamId } = useSelector((state: RootState) => state.persisted.user.value);

  if (!token) {
    return <Navigate to="/" />;
  }

  if (!hasAccess(teamId, path)) {
    return <Navigate to={RoutePath.AGENT_DASHBOARD} />;
  }

  return element;
};

export default ProtectedRoute;
