import React, { useEffect, useState } from 'react';
import { Table, Card, Row, Col, Statistic, Input, Tag, Typography, Spin } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { getAllAgents } from '../../api/agent.api';
import { Link } from 'react-router-dom';

const { Title, Text } = Typography;
const { Search } = Input;

interface AgentMetrics {
  _id: string;
  aId: string;
  callMetrics: {
    totalCalls: number;
    averageCallScore: number;
    averageCallDuration: number;
  };
}

const AllAgents = () => {
  const [searchText, setSearchText] = useState<string>('');
  const [agentData, setAgentData] = useState<AgentMetrics[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    getAllAgents().then((data) => {
      setAgentData(data);
      setIsLoading(false);
    });
  }, []);

  //   const overallMetrics = agentData.reduce(
  //     (acc, agent) => {
  //       acc.totalCalls += agent.callMetrics.totalCalls;
  //       acc.totalAgents += 1;
  //       acc.avgScore += agent.callMetrics.averageCallScore * agent.callMetrics.totalCalls;
  //       acc.avgDuration += agent.callMetrics.averageCallDuration * agent.callMetrics.totalCalls;
  //       return acc;
  //     },
  //     { totalCalls: 0, totalAgents: 0, avgScore: 0, avgDuration: 0 }
  //   );

  //   const avgScore = overallMetrics.avgScore / overallMetrics.totalCalls;
  //   const avgDuration = overallMetrics.avgDuration / overallMetrics.totalCalls;

  const columns: ColumnsType<AgentMetrics> = [
    {
      title: 'Agent ID',
      dataIndex: ['aId'],
      key: 'aId',
      sorter: (a, b) => a.aId.localeCompare(b.aId),
      render: (text: string, record: AgentMetrics) => (
        <Link to={`/agent?agentId=${record._id}`}>{text}</Link>
      ),
    },
    {
      title: 'Total Calls',
      dataIndex: ['callMetrics', 'totalCalls'],
      key: 'totalCalls',
      sorter: (a, b) => a.callMetrics.totalCalls - b.callMetrics.totalCalls,
    },
    {
      title: 'Average Score',
      dataIndex: ['callMetrics', 'averageCallScore'],
      key: 'averageCallScore',
      render: (score: number) => (
        <Tag color={score >= 65 ? 'green' : score >= 55 ? 'orange' : 'red'}>
          {score.toFixed(2)}%
        </Tag>
      ),
      sorter: (a, b) => a.callMetrics.averageCallScore - b.callMetrics.averageCallScore,
    },
    {
      title: 'Average Duration',
      dataIndex: ['callMetrics', 'averageCallDuration'],
      key: 'averageCallDuration',
      render: (duration: number) => `${Math.floor(duration / 60)}m ${Math.round(duration % 60)}s`,
      sorter: (a, b) => a.callMetrics.averageCallDuration - b.callMetrics.averageCallDuration,
    },
  ];

  const filteredData = agentData.filter((agent) =>
    agent.aId.toLowerCase().includes(searchText.toLowerCase())
  );

  return (
    <div style={{ padding: '24px' }}>
      <Title level={2}>All Agents</Title>

      {isLoading ? (
        <Spin size="default" />
      ) : (
        <>
          {/* <Row gutter={[16, 16]}>
            <Col span={6}>
              <Card>
                <Statistic title="Total Agents" value={overallMetrics.totalAgents} />
              </Card>
            </Col>
            <Col span={6}>
              <Card>
                <Statistic title="Total Calls" value={overallMetrics.totalCalls} />
              </Card>
            </Col>
            <Col span={6}>
              <Card>
                <Statistic
                  title="Average Score"
                  value={avgScore}
                  precision={2}
                  suffix="%"
                  valueStyle={{ color: avgScore >= 65 ? '#3f8600' : '#cf1322' }}
                />
              </Card>
            </Col>
            <Col span={6}>
              <Card>
                <Statistic
                  title="Average Duration"
                  value={Math.floor(avgDuration / 60)}
                  suffix={`m ${Math.round(avgDuration % 60)}s`}
                />
              </Card>
            </Col>
          </Row> */}
          <Card style={{ marginTop: '24px' }}>
            <div style={{ marginBottom: '16px' }}>
              <Search
                placeholder="Search by Agent ID"
                onChange={(e) => setSearchText(e.target.value)}
                style={{ width: 200 }}
              />
            </div>
            <Table
              columns={columns}
              dataSource={filteredData}
              rowKey="aId"
              pagination={{
                hideOnSinglePage: true,
                showSizeChanger: false,
                total: filteredData.length,
                showTotal: (total) => <Text>Total: {total} agents</Text>,
              }}
            />
          </Card>
        </>
      )}
    </div>
  );
};

export default AllAgents;
