import React, { useEffect, useState } from 'react';
import { Table, Tag, Spin, DatePicker, Button, Card, Typography, Row, Col, Statistic } from 'antd';
import { ColumnsType } from 'antd/es/table';
import './CustomerJourney.css';
import { Link } from 'react-router-dom';
import { RoutePath } from '../../constants/routePaths';
import { getCustomerCalls } from '../../api/customer.api';

const { RangePicker } = DatePicker;
const { Title, Text } = Typography;

interface CallData {
  id: string;
  key: string;
  timestamp: string;
  duration: number;
  score: string;
  status: 'completed' | 'processing' | 'waiting' | 'failed';
  disposition: string;
}

interface CustomerData {
  _id: string;
  cId: string;
  loanAmount: string;
}

const CustomerJourney: React.FC = () => {
  const customerId = new URL(window.location.href).searchParams.get('customerId');
  if (!customerId) {
    window.location.href = RoutePath.HOME;
  }

  const [callData, setCallData] = useState<CallData[]>([]);
  const [customerData, setCustomerData] = useState<CustomerData>();
  const [isLoading, setIsLoading] = useState(true);
  const [pickerValue, setPickerValue] = useState<[string, string] | null>(null);

  useEffect(() => {
    getCustomerCalls({ customerId }).then((data) => {
      const calls = data.calls.map((call: any) => ({
        id: call._id,
        key: call.key,
        timestamp: new Date(call.timestamp ?? Date.now()).toLocaleString(),
        duration: call.duration,
        score:
          call.callScore && call.totalScore && call.totalScore !== 0
            ? `${Math.round((call.callScore / call.totalScore) * 10000) / 100}%`
            : '-',
        status: call.processStatus,
        disposition: call.disposition,
      }));
      setCallData(
        calls.sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime())
      );
      setCustomerData(data.customer);
      setIsLoading(false);
    });
  }, []);

  const columns: ColumnsType<CallData> = [
    {
      title: 'Call',
      dataIndex: 'key',
      key: 'key',
      render: (key: string) => <span>{key.split('/')[1]}</span>,
      width: '30%',
    },
    // {
    //   title: 'Score',
    //   dataIndex: 'score',
    //   key: 'score',
    //   width: '10%',
    // },
    {
      title: 'Disposition',
      dataIndex: 'disposition',
      key: 'disposition',
      width: '10%',
      render: (disposition: string) => {
        const colorMap = {
          PTP: 'green',
          RTP: 'red',
          CALLBACK: 'blue',
          ALREADY_PAID: 'purple',
          DISPUTE: 'orange',
          NRPC: 'volcano',
          NEUTRAL: 'default',
        };
        return <Tag color={colorMap[disposition as keyof typeof colorMap]}>{disposition}</Tag>;
      },
    },
    {
      title: 'Call Date & Time',
      dataIndex: 'timestamp',
      key: 'timestamp',
      width: '20%',
      filterDropdown: ({ setSelectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <RangePicker
            onChange={(dates, dateStrings) => {
              if (dates) {
                setPickerValue([dateStrings[0], dateStrings[1]]); // Set range
                setSelectedKeys(dateStrings); // Set selected keys
              } else {
                setPickerValue(null); // Clear range
                setSelectedKeys([]);
              }
            }}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Button
            type="link"
            onClick={() => {
              setPickerValue(null); // Clear picker value
              clearFilters && clearFilters();
              confirm();
            }}
            style={{ marginRight: 8 }}
          >
            Reset
          </Button>
          <Button
            type="primary"
            onClick={() => {
              confirm();
            }}
          >
            Filter
          </Button>
        </div>
      ),
      onFilter: (value, record) => {
        if (pickerValue) {
          const [startDate, endDate] = pickerValue;
          const recordDate = new Date(record.timestamp).toISOString();
          return (
            recordDate >= new Date(startDate).toISOString() &&
            recordDate <= new Date(endDate).toISOString()
          );
        }
        return false;
      },
    },
    {
      title: 'Processing Status',
      dataIndex: 'status',
      key: 'status',
      width: '15%',
      render: (status: string) => {
        const statusColors: Record<string, string> = {
          completed: 'green',
          processing: 'gold',
          waiting: 'blue',
          failed: 'red',
        };
        return <Tag color={statusColors[status]}>{status}</Tag>;
      },
      filters: [
        { text: 'Completed', value: 'Completed' },
        { text: 'Processing', value: 'Processing' },
        { text: 'Waiting', value: 'Waiting' },
        { text: 'Failed', value: 'Failed' },
      ],
      onFilter: (value, record) => record.status === value,
    },
    {
      dataIndex: 'id',
      key: 'id',
      width: '10%',
      render: (id: string, record: CallData) => {
        if (record.status === 'completed') {
          return <Link to={`${RoutePath.CALL_ANALYSIS}?callId=${id}`}>View Call</Link>;
        } else {
          return <span></span>;
        }
      },
    },
  ];

  return (
    <div className="main-content">
      <Title level={2}>Customer Journey</Title>
      {isLoading ? (
        <Spin size="default" />
      ) : (
        <>
          <Row gutter={[24, 24]}>
            <Col span={8}>
              <Card>
                <Statistic title="Customer ID" value={customerData?.cId} />
              </Card>
            </Col>
            <Col span={8}>
              <Card>
                <Statistic title="Loan Amount" value={customerData?.loanAmount} prefix="₹" />
              </Card>
            </Col>
            <Col span={8}>
              <Card>
                <Statistic
                  title="Total Calls"
                  value={callData.length}
                  // valueStyle={{ color: '#722ed1' }}
                />
              </Card>
            </Col>
          </Row>

          <Card style={{ marginTop: 24 }}>
            <Table
              columns={columns}
              dataSource={callData}
              rowKey="id"
              pagination={{
                pageSize: 10,
              }}
            />
          </Card>
        </>
      )}
    </div>
  );
};

export default CustomerJourney;
