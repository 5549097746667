import AWS from 'aws-sdk';
import React, { useEffect, useRef, useState } from 'react';
import {
  Layout,
  Card,
  Spin,
  Table,
  Tabs,
  Row,
  Col,
  Tag,
  Statistic,
  Typography,
  Button,
} from 'antd';
import type { TabsProps } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { PlayCircleOutlined } from '@ant-design/icons';
import './CallAnalysis.css';
import { getCall } from '../../api/call.api';
import { RoutePath } from '../../constants/routePaths';
import { useNavigate } from 'react-router-dom';
import { COMPLETE_TEAMS } from '../../config/teamPermissions';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

const { Content } = Layout;

const config = {
  bucketName: process.env.REACT_APP_AWS_BUCKET_NAME,
  region: process.env.REACT_APP_AWS_REGION_NAME,
  accessKeyId: process.env.REACT_APP_AWS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET,
};

interface TranscriptSnippet {
  start: number;
  end: number;
  speaker: string;
  text: string;
}

interface Evidence {
  start: number;
  end: number;
}

interface AnalysisItem {
  field: string;
  score: number;
  remarks: string;
  evidence?: Evidence[];
}

interface PTPCheck {
  check: string;
  passed: boolean;
  remarks: string;
}

interface PaymentIntentResponse {
  ptpScore?: number;
  ptpResult?: string;
  ptpExplanation?: PTPCheck[];
  noPtpType?: string;
  noPtpExplanation?: string;
}

interface Disposition {
  tag: 'PTP' | 'RTP' | 'CALLBACK' | 'ALREADY_PAID' | 'DISPUTE' | 'NRPC' | 'NEUTRAL';
  justification: string;
  paymentIntentResponse?: PaymentIntentResponse;
}

interface ImportantKeyword {
  start: number;
  end: number;
  score: number;
  text: string;
  sentence: string;
}

interface ImportantKeywords {
  detectedHighAlertKeyPhrases: ImportantKeyword[];
  detectedNegativeKeyPhrases: ImportantKeyword[];
}

interface Agent {
  _id: string;
  aId: string;
  teamId: string;
}

interface Customer {
  _id: string;
  cId: string;
  loanAmount: string;
  teamId: string;
}

interface CallAnalysis {
  _id: string;
  key: string;
  excelKey: string;
  agentId: string;
  customerId: string;
  duration: number;
  timestamp: number;
  totalScore: number;
  callScore: number;
  processStatus: string;
  transcript: TranscriptSnippet[];
  analysis: AnalysisItem[];
  disposition: Disposition;
  importantKeywords: ImportantKeywords;
  agent?: Agent;
  customer?: Customer;
}

interface CallAnalysisResponse {
  call: CallAnalysis;
  agent: Agent;
  customer: Customer;
}

interface ImportantKeywordsProps {
  importantKeywords: ImportantKeywords;
  onPlayAudio: (start: number, end: number) => void;
}

const ImportantKeywordsAnalysis: React.FC<ImportantKeywordsProps> = ({
  importantKeywords,
  onPlayAudio,
}) => {
  const columns: ColumnsType<ImportantKeyword> = [
    {
      title: 'Text',
      dataIndex: 'text',
      key: 'text',
      width: '20%',
    },
    {
      title: 'Context',
      dataIndex: 'sentence',
      key: 'sentence',
      width: '50%',
    },
    {
      title: 'Confidence',
      dataIndex: 'score',
      key: 'score',
      width: '15%',
      render: (score: number) => {
        const percentage = Math.round(score * 100);
        const color = percentage >= 80 ? 'green' : percentage >= 60 ? 'orange' : 'red';
        return <Tag color={color}>{percentage}%</Tag>;
      },
    },
    {
      title: 'Action',
      key: 'action',
      width: '15%',
      render: (_, record) => (
        <Button
          type="link"
          icon={<PlayCircleOutlined />}
          onClick={() => onPlayAudio(record.start, record.end)}
        >
          Play
        </Button>
      ),
    },
  ];

  return (
    <div className="important-keywords-container">
      <Tabs
        items={[
          {
            key: '1',
            label: (
              <span>
                High Alert Keywords
                <Tag color="red" style={{ marginLeft: 8 }}>
                  {importantKeywords.detectedHighAlertKeyPhrases.length}
                </Tag>
              </span>
            ),
            children: (
              <Table
                columns={columns}
                dataSource={importantKeywords.detectedHighAlertKeyPhrases}
                pagination={false}
                size="small"
                rowKey={(record) => `${record.text}-${record.start}`}
              />
            ),
          },
          {
            key: '2',
            label: (
              <span>
                Negative Keywords
                <Tag color="orange" style={{ marginLeft: 8 }}>
                  {importantKeywords.detectedNegativeKeyPhrases.length}
                </Tag>
              </span>
            ),
            children: (
              <Table
                columns={columns}
                dataSource={importantKeywords.detectedNegativeKeyPhrases}
                pagination={false}
                size="small"
                rowKey={(record) => `${record.text}-${record.start}`}
              />
            ),
          },
        ]}
      />
    </div>
  );
};

const formatTimestamp = (seconds: number): string => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
};

const formatCheckName = (check: string) => {
  return check
    .split('_')
    .map((word) => word.charAt(0) + word.slice(1).toLowerCase())
    .join(' ');
};

const CallAnalysis: React.FC = () => {
  const callId = new URL(window.location.href).searchParams.get('callId');
  if (!callId) {
    window.location.href = RoutePath.HOME;
  }
  const navigate = useNavigate();
  const [audioUrl, setAudioUrl] = useState<string>('');
  const [audioTimestamp, setAudioTimestamp] = useState<{ start: number; end: number } | null>(null);
  const [agentDetails, setAgentDetails] = useState<Agent>();
  const [customerDetails, setCustomerDetails] = useState<Customer>();
  const [callAnalysis, setCallAnalysis] = useState<CallAnalysis>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [currentSnippet, setCurrentSnippet] = useState<number | null>(null);
  const [userScrolled, setUserScrolled] = useState(false);
  const scrollTimeoutRef = useRef<NodeJS.Timeout>();

  const audioRef = useRef<HTMLAudioElement | null>(null);
  const transcriptContainerRef = useRef<HTMLDivElement>(null);

  const { teamId } = useSelector((state: RootState) => state.persisted.user.value);

  useEffect(() => {
    getCall({ callId }).then(async (response: CallAnalysisResponse) => {
      const signedUrl = await getSignedUrlForRead(response.call.key);
      setAudioUrl(signedUrl);
      setCallAnalysis(response.call);
      setAgentDetails(response.agent);
      setCustomerDetails(response.customer);
      setIsLoading(false);
    });
  }, [callId]);

  useEffect(() => {
    if (audioRef.current && audioTimestamp !== null) {
      const audio = audioRef.current;
      const handleTimeUpdate = () => {
        if (audio.currentTime >= audioTimestamp.end) {
          audio.pause();
          audio.removeEventListener('timeupdate', handleTimeUpdate);
        }
      };
      audio.currentTime = audioTimestamp.start;
      audio.play();
      audio.scrollIntoView({ behavior: 'smooth' });
      audio.addEventListener('timeupdate', handleTimeUpdate);
      return () => {
        audio.removeEventListener('timeupdate', handleTimeUpdate);
      };
    }
  }, [audioTimestamp]);

  useEffect(() => {
    const container = transcriptContainerRef.current;
    if (container) {
      const handleScroll = () => {
        setUserScrolled(true);
        if (scrollTimeoutRef.current) {
          clearTimeout(scrollTimeoutRef.current);
        }
        scrollTimeoutRef.current = setTimeout(() => {
          setUserScrolled(false);
        }, 5000);
      };

      container.addEventListener('scroll', handleScroll);
      return () => {
        container.removeEventListener('scroll', handleScroll);
        if (scrollTimeoutRef.current) {
          clearTimeout(scrollTimeoutRef.current);
        }
      };
    }
  }, []);

  useEffect(() => {
    if (audioRef.current) {
      const audio = audioRef.current;

      const handleTimeUpdate = () => {
        const currentTime = audio.currentTime;
        const snippetIndex = callAnalysis?.transcript.findIndex(
          (snippet) => currentTime >= snippet.start && currentTime <= snippet.end
        );

        if (snippetIndex !== -1 && snippetIndex !== currentSnippet) {
          setCurrentSnippet(snippetIndex);

          // Only auto-scroll if user hasn't manually scrolled recently
          if (!userScrolled) {
            const snippetElement = document.querySelector(`[data-snippet-index="${snippetIndex}"]`);
            snippetElement?.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
        }
      };

      audio.addEventListener('timeupdate', handleTimeUpdate);
      audio.addEventListener('ended', () => setCurrentSnippet(null));
      audio.addEventListener('pause', () => setCurrentSnippet(null));

      return () => {
        audio.removeEventListener('timeupdate', handleTimeUpdate);
        audio.removeEventListener('ended', () => setCurrentSnippet(null));
        audio.removeEventListener('pause', () => setCurrentSnippet(null));
      };
    }
  }, [audioRef, callAnalysis?.transcript, currentSnippet, userScrolled]);

  const handlePlayAudioClick = (start: number, end: number) => {
    if (audioRef.current) {
      audioRef.current.currentTime = start;
      audioRef.current.play();
    }
  };

  const getSignedUrlForRead = async (fileKey: string): Promise<string> => {
    const s3 = new AWS.S3({
      accessKeyId: config.accessKeyId,
      secretAccessKey: config.secretAccessKey,
      region: config.region,
    });

    const params = {
      Bucket: config.bucketName!,
      Key: fileKey,
      Expires: 60 * 10, // URL expiration time in seconds (10 minutes here)
    };

    return new Promise((resolve, reject) => {
      s3.getSignedUrl('getObject', params, (err, url) => {
        if (err) {
          reject(err);
        } else {
          resolve(url);
        }
      });
    });
  };

  // const handleDeleteClick = async (): Promise<void> => {
  //   try {
  //     const deleteResponse = await deleteCall({ callId });
  //     console.log(deleteResponse);
  //     alert("Call deleted successfully");
  //     navigate(RoutePath.DASHBOARD);
  //   }
  //   catch (error) {
  //     console.error(error);
  //     alert("Error deleting call");
  //   }
  // }

  const tableColumns: ColumnsType<AnalysisItem> = [
    {
      title: 'Field',
      dataIndex: 'field',
      key: 'field',
    },
    {
      title: 'Score',
      dataIndex: 'score',
      key: 'score',
      // render: (value: boolean) => {
      //   return <span>{value ? "✅" : "❌"}</span>;
      // },
    },
    {
      title: 'Remarks',
      dataIndex: 'remarks',
      key: 'remarks',
    },
  ];

  // const tabItems: TabsProps["items"] = callAnalysis.analysis.map((a,n) => {
  //   const tableData: AnalysisTableData[] = Object.entries(a.response).map(([key, value]) => ({
  //     field: key,
  //     value: value.value,
  //     remarks: value.remarks
  //   }))
  //   return {
  //     key: `${n}`,
  //     label: a.title,
  //     children: <Table columns={tableColumns} dataSource={tableData} rowKey="agentId" />
  //   }
  // });

  const analysisColumns: ColumnsType<AnalysisItem> = [
    {
      title: 'Field',
      dataIndex: 'field',
      key: 'field',
      width: '20%',
    },
    {
      title: 'Score',
      dataIndex: 'score',
      key: 'score',
      width: '10%',
      render: (score: number) => (
        <Tag color={score >= 4 ? 'green' : score >= 2 ? 'orange' : 'red'}>{score}/5</Tag>
      ),
    },
    {
      title: 'Remarks',
      dataIndex: 'remarks',
      key: 'remarks',
      width: '55%',
    },
    {
      title: 'Evidence',
      dataIndex: 'evidence',
      key: 'evidence',
      width: '15%',
      render: (evidence: any[], record: any) =>
        evidence?.length > 0 && (
          <button
            onClick={() => handlePlayAudioClick(evidence[0].start, evidence[0].end)}
            style={{ border: 'none', background: 'none', cursor: 'pointer', color: '#1890ff' }}
          >
            <PlayCircleOutlined /> Play
          </button>
        ),
    },
  ];

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Analysis',
      children: (
        <div style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 250px)', paddingBottom: '16px' }}>
          <Table columns={analysisColumns} dataSource={callAnalysis?.analysis} rowKey="field" />
        </div>
      ),
    },
    ...(COMPLETE_TEAMS.includes(teamId)
      ? [
          {
            key: '2',
            label: 'Disposition',
            children: (
              <div style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 250px)' }}>
                <Card bodyStyle={{ padding: '24px' }}>
                  <Row gutter={[16, 16]}>
                    <Col xs={24} sm={12} md={8}>
                      <Statistic
                        title="Disposition"
                        value={callAnalysis?.disposition?.tag}
                        valueStyle={{
                          color:
                            callAnalysis?.disposition?.tag === 'PTP'
                              ? '#3f8600'
                              : callAnalysis?.disposition?.tag === 'RTP'
                                ? '#cf1322'
                                : '#1890ff',
                        }}
                      />
                    </Col>
                    {callAnalysis?.disposition?.tag === 'PTP' &&
                      callAnalysis?.disposition?.paymentIntentResponse && (
                        <>
                          <Col xs={24} sm={12} md={8}>
                            <Statistic
                              title="PTP Score"
                              value={callAnalysis.disposition.paymentIntentResponse.ptpScore}
                              suffix="/12"
                            />
                          </Col>
                          <Col xs={24} sm={12} md={8}>
                            <Statistic
                              title="PTP Result"
                              value={callAnalysis.disposition.paymentIntentResponse.ptpResult}
                            />
                          </Col>
                        </>
                      )}
                  </Row>

                  <div style={{ marginTop: 24 }}>
                    <Typography.Title level={5}>Justification</Typography.Title>
                    <Typography.Paragraph style={{ whiteSpace: 'pre-wrap' }}>
                      {callAnalysis?.disposition?.justification}
                    </Typography.Paragraph>
                  </div>

                  {callAnalysis?.disposition?.tag === 'PTP' &&
                    callAnalysis?.disposition?.paymentIntentResponse?.ptpExplanation && (
                      <div style={{ marginTop: 24 }}>
                        <Typography.Title level={5}>PTP Checks</Typography.Title>
                        <Table
                          dataSource={callAnalysis.disposition.paymentIntentResponse.ptpExplanation}
                          columns={[
                            {
                              title: 'Check',
                              dataIndex: 'check',
                              key: 'check',
                              className: 'check-cell',
                              render: (text: string) => (
                                <span className="check-name">{formatCheckName(text)}</span>
                              ),
                            },
                            {
                              title: 'Status',
                              dataIndex: 'passed',
                              key: 'passed',
                              width: '15%',
                              render: (passed: boolean) => (
                                <Tag color={passed ? 'green' : 'red'}>
                                  {passed ? 'PASSED' : 'FAILED'}
                                </Tag>
                              ),
                            },
                            {
                              title: 'Remarks',
                              dataIndex: 'remarks',
                              key: 'remarks',
                              width: '60%',
                            },
                          ]}
                          pagination={false}
                          size="small"
                          scroll={{ x: true }}
                        />
                      </div>
                    )}

                  {callAnalysis?.disposition?.tag === 'RTP' &&
                    callAnalysis?.disposition?.paymentIntentResponse && (
                      <div style={{ marginTop: 24 }}>
                        <Typography.Title level={5}>RTP Details</Typography.Title>
                        <Card bordered={false} style={{ background: '#f5f5f5' }}>
                          <Typography.Paragraph>
                            <strong>Type: </strong>
                            {callAnalysis.disposition.paymentIntentResponse.noPtpType}
                          </Typography.Paragraph>
                          <Typography.Paragraph style={{ marginBottom: 0 }}>
                            <strong>Explanation: </strong>
                            {callAnalysis.disposition.paymentIntentResponse.noPtpExplanation}
                          </Typography.Paragraph>
                        </Card>
                      </div>
                    )}
                </Card>
              </div>
            ),
          },
        ]
      : []),
    {
      key: '3',
      label: 'Important Keywords',
      children: (
        <div style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 250px)' }}>
          <ImportantKeywordsAnalysis
            importantKeywords={callAnalysis?.importantKeywords}
            onPlayAudio={handlePlayAudioClick}
          />
        </div>
      ),
    },
  ];

  const calculateScorePercentage = (callScore: number, totalScore: number): number => {
    if (!totalScore) return 0;
    return Number(((callScore / totalScore) * 100).toFixed(2));
  };

  return (
    <Layout className="call-analysis-layout">
      <Content className="call-analysis-content">
        <Typography.Title level={3}>Call Analysis</Typography.Title>
        <Typography.Text
          type="secondary"
          style={{ fontSize: '16px', display: 'block', marginBottom: '24px' }}
        >
          {callAnalysis?.key}
        </Typography.Text>

        <Row gutter={[24, 24]}>
          <Col span={6}>
            <Card className="metrics-card">
              {isLoading ? (
                <div className="loading-container">
                  <Spin size="large" />
                </div>
              ) : (
                <div className="stats-container">
                  <div className="stat-item">
                    <Statistic title="Agent ID" value={agentDetails?.aId || 'N/A'} />
                  </div>
                  <div className="stat-item">
                    {COMPLETE_TEAMS.includes(teamId) ? (
                      <div
                        onClick={() =>
                          customerDetails?.cId &&
                          navigate(
                            `${RoutePath.CUSTOMER_DETAILS}?customerId=${customerDetails._id}`
                          )
                        }
                        style={{ cursor: 'pointer' }}
                      >
                        <Statistic title="Customer ID" value={customerDetails?.cId || 'N/A'} />
                      </div>
                    ) : (
                      <Statistic title="Customer ID" value={customerDetails?.cId || 'N/A'} />
                    )}
                  </div>
                  <div className="stat-item">
                    <Statistic
                      title="Loan Amount"
                      value={customerDetails?.loanAmount || 'N/A'}
                      prefix="₹"
                    />
                  </div>
                  {COMPLETE_TEAMS.includes(teamId) && (
                    <div className="stat-item">
                      <Statistic
                        title="Disposition"
                        value={callAnalysis?.disposition?.tag || 'N/A'}
                      />
                    </div>
                  )}
                  <div className="stat-item">
                    <Statistic
                      title="Call Score"
                      value={Number(
                        calculateScorePercentage(
                          callAnalysis?.callScore || 0,
                          callAnalysis?.totalScore || 100
                        ).toFixed(2)
                      )}
                      suffix="%"
                      valueStyle={{
                        color:
                          calculateScorePercentage(
                            callAnalysis?.callScore || 0,
                            callAnalysis?.totalScore || 100
                          ) >= 60
                            ? '#3f8600'
                            : '#cf1322',
                      }}
                    />
                  </div>
                  <div className="stat-item">
                    <Statistic
                      title="Duration"
                      value={formatTimestamp(callAnalysis?.duration || 0)}
                    />
                  </div>
                </div>
              )}
            </Card>
          </Col>

          <Col span={18}>
            <Card title="Call Recording & Transcript" className="transcript-card">
              <div>
                <div className="audio-player">
                  {audioUrl && (
                    <audio ref={audioRef} controls style={{ width: '100%' }}>
                      <source src={audioUrl} type="audio/mpeg" />
                      <track kind="captions" />
                    </audio>
                  )}
                </div>
                <div className="transcript-container" ref={transcriptContainerRef}>
                  {isLoading ? (
                    <div className="loading-container">
                      <Spin size="large" />
                    </div>
                  ) : (
                    callAnalysis?.transcript.map((snippet, index) => (
                      <div
                        key={index}
                        data-snippet-index={index}
                        className={`transcript-line ${snippet.speaker.toLowerCase()} ${
                          currentSnippet === index ? 'active-snippet' : ''
                        }`}
                        onClick={() => handlePlayAudioClick(snippet.start, snippet.end)}
                      >
                        <div className="transcript-header">
                          <Tag color={snippet.speaker === 'Agent' ? 'blue' : 'green'}>
                            {snippet.speaker}
                          </Tag>
                          <span className="transcript-timestamp">
                            {formatTimestamp(snippet.start)} - {formatTimestamp(snippet.end)}
                          </span>
                        </div>
                        <div className="transcript-text">{snippet.text}</div>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </Card>
          </Col>

          <Col span={24}>
            <Card className="analysis-card">
              {isLoading ? (
                <div className="loading-container">
                  <Spin size="large" />
                </div>
              ) : (
                <Tabs items={items} />
              )}
            </Card>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default CallAnalysis;
