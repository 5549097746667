import { RoutePath } from '../constants/routePaths';
import { getTeamConfig } from './teamConfig';

const { COMPLETE_TEAMS, QUALITY_TEAMS } = getTeamConfig();

export { COMPLETE_TEAMS, QUALITY_TEAMS };

export const QUALITY_TEAM_ROUTES = [
  RoutePath.HOME,
  RoutePath.AGENT_DASHBOARD,
  RoutePath.COMPLIANCE_DASHBOARD,
  RoutePath.ALL_AGENTS,
  RoutePath.ALL_CALLS,
  RoutePath.AGENT_DETAILS,
  RoutePath.UPLOAD_CALL,
  RoutePath.EXCEL_CALLS,
  RoutePath.EXCEL_UPLOADS,
  RoutePath.CALL_ANALYSIS,
];

export const QUALITY_TEAM_COMPONENTS = [
  'AgentDashboard',
  'AllCalls',
  'CallAnalysis',
  'CustomerJourney',
];

export const hasAccess = (teamId: string, path: string): boolean => {
  if (COMPLETE_TEAMS.includes(teamId)) {
    return true;
  }
  return QUALITY_TEAMS.includes(teamId) && QUALITY_TEAM_ROUTES.includes(path as RoutePath);
};

export const hasComponentAccess = (teamId: string, componentName: string): boolean => {
  if (COMPLETE_TEAMS.includes(teamId)) {
    return true;
  }
  return QUALITY_TEAMS.includes(teamId) && QUALITY_TEAM_COMPONENTS.includes(componentName);
};
