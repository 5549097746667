import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Statistic, Table, Typography, Spin, Tabs, Button, Tooltip } from 'antd';
import { InfoCircleOutlined, UpOutlined, DownOutlined } from '@ant-design/icons';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RechartsTooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import type { ColumnsType } from 'antd/es/table';
import './AgentDashboard.css';
import { getAgentDashboardMetrics } from '../../api/dashboard.api';
import { Link } from 'react-router-dom';
import { RoutePath } from '../../constants/routePaths';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { COMPLETE_TEAMS } from '../../config/teamPermissions';
import { exportAgentsNeedingTrainingReport } from '../../api/export.api';

const { Title } = Typography;

interface TopPerformingAgent {
  agentId: string;
  aId: string;
  score: number;
}

interface UnderperformingAgent {
  agentId: string;
  aId: string;
  sectionScore: number;
  overallScore: number;
}

interface TrainingEvidence {
  callId: string;
  callKey: string;
  evidence: { start: number; end: number }[];
}

interface TrainingNeed {
  field: string;
  trainingNeeds: string;
  calls: TrainingEvidence[];
}

interface AgentTNI {
  aId: string;
  agentId: string;
  trainingNeeds: TrainingNeed[];
  section: string;
}

interface TeamTNI {
  data: AgentTNI[];
  lastUpdated: string;
}

interface AgentMetrics {
  overallTeamScore: number;
  currentMonthSectionMetrics: {
    [key: string]: {
      averageScore: number;
    };
  };
  lastMonthSectionMetrics: {
    [key: string]: {
      averageScore: number;
    };
  };
  topPerformingAgents: TopPerformingAgent[];
  underperformingAgentsBySection: {
    [key: string]: UnderperformingAgent[];
  };
  averageCallDuration: number;
  complianceRate: number;
  totalCalls: number;
  topPtpPerformers: {
    aId: string;
    agentId: string;
    ptpPercentage: number;
  }[];
  teamTni?: TeamTNI;
}

interface CombinedAgentData {
  aId: string;
  agentId: string;
  sectionScore?: number;
  overallScore?: number;
  trainingNeeds?: TrainingNeed[];
}

interface CombinedSectionData {
  underperformingAgents: CombinedAgentData[];
  lastUpdated?: string;
}

const AgentPerformanceAnalysis: React.FC<{
  underperformingAgents: { [key: string]: UnderperformingAgent[] };
  teamTni?: TeamTNI;
}> = ({ underperformingAgents, teamTni }) => {
  const [expandedRowKey, setExpandedRowKey] = useState<string | null>(null);
  const [isDownloading, setIsDownloading] = useState(false);

  const handleTabChange = () => {
    setExpandedRowKey(null);
  };

  // Combine data from both sources
  const combinedData: Record<string, CombinedSectionData> = {};

  // First, process underperforming agents
  Object.entries(underperformingAgents).forEach(([section, agents]) => {
    if (!combinedData[section]) {
      combinedData[section] = { underperformingAgents: [] };
    }
    combinedData[section].underperformingAgents = agents.map((agent) => ({
      aId: agent.aId,
      agentId: agent.agentId,
      sectionScore: agent.sectionScore,
      overallScore: agent.overallScore,
    }));
  });

  // Then, merge TNI data
  if (teamTni) {
    teamTni.data.forEach((tniAgent) => {
      const section = tniAgent.section;
      if (!combinedData[section]) {
        combinedData[section] = { underperformingAgents: [] };
      }

      // Find or create agent entry
      let agentEntry = combinedData[section].underperformingAgents.find(
        (a) => a.aId === tniAgent.aId
      );

      if (!agentEntry) {
        agentEntry = {
          aId: tniAgent.aId,
          agentId: tniAgent.agentId,
          trainingNeeds: tniAgent.trainingNeeds,
        };
        combinedData[section].underperformingAgents.push(agentEntry);
      } else {
        agentEntry.trainingNeeds = tniAgent.trainingNeeds;
      }
    });

    // Add lastUpdated to all sections
    Object.keys(combinedData).forEach((section) => {
      combinedData[section].lastUpdated = teamTni.lastUpdated;
    });
  }

  const handleExportAgentsNeedingTrainingReport = async () => {
    try {
      setIsDownloading(true);
      const { url } = await exportAgentsNeedingTrainingReport();
      const link = document.createElement('a');
      link.href = url;
      link.download = '';
      link.click();
    } catch (error) {
      console.error('Error exporting report:', error);
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <Row gutter={[16, 16]} style={{ marginTop: '24px' }}>
      <Col span={24}>
        <Card
          title={
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <div>
                <span>Agent Performance Analysis</span>
                <Typography.Text type="secondary" style={{ fontSize: '12px', marginLeft: '12px' }}>
                  (Agents in bottom 20% with section scores below 75%)
                </Typography.Text>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                {teamTni && (
                  <Typography.Text type="secondary" style={{ fontSize: '12px' }}>
                    Last Updated: {new Date(teamTni.lastUpdated).toLocaleDateString('en-GB')}
                  </Typography.Text>
                )}
                <Button onClick={handleExportAgentsNeedingTrainingReport} loading={isDownloading}>
                  Download Report
                </Button>
              </div>
            </div>
          }
        >
          <Tabs
            type="card"
            onChange={handleTabChange}
            items={Object.entries(combinedData).map(([section, data]) => ({
              key: section,
              label: section,
              children:
                data.underperformingAgents.length === 0 ? (
                  <div
                    style={{
                      textAlign: 'center',
                      padding: '40px 0',
                      background: '#fafafa',
                      borderRadius: '8px',
                      margin: '12px 0',
                    }}
                  >
                    <Typography.Text type="secondary" style={{ fontSize: '16px' }}>
                      No agents currently need training in this section
                    </Typography.Text>
                  </div>
                ) : (
                  <Table
                    dataSource={data.underperformingAgents}
                    rowKey={(record) => `${section}-${record.aId}`}
                    style={{ marginTop: '12px' }}
                    expandable={{
                      showExpandColumn: false,
                      expandedRowKeys: expandedRowKey ? [expandedRowKey] : [],
                      onExpand: (expanded, record) => {
                        setExpandedRowKey(expanded ? `${section}-${record.aId}` : null);
                      },
                      expandedRowRender: (record) => {
                        if (!record.trainingNeeds?.length) {
                          return (
                            <Typography.Text
                              type="secondary"
                              style={{ padding: '24px', display: 'block' }}
                            >
                              No specific training needs identified
                            </Typography.Text>
                          );
                        }
                        return (
                          <div
                            style={{
                              padding: '16px 24px',
                              margin: '0 -16px', // Negative margin to extend background
                              background: '#f8f9fa',
                              borderLeft: '4px solid #1890ff', // Blue accent line to show connection
                              boxShadow: 'inset 0 2px 4px rgba(0,0,0,0.05)', // Subtle inner shadow
                            }}
                          >
                            <Typography.Title level={5} style={{ marginBottom: '16px' }}>
                              Training Details
                            </Typography.Title>
                            <Table
                              dataSource={record.trainingNeeds}
                              pagination={false}
                              style={{
                                background: 'white',
                                borderRadius: '8px',
                                boxShadow: '0 1px 2px rgba(0,0,0,0.03)',
                              }}
                              columns={[
                                {
                                  title: 'Field',
                                  dataIndex: 'field',
                                  key: 'field',
                                  width: '15%',
                                  render: (text) => (
                                    <Typography.Text strong>{text}</Typography.Text>
                                  ),
                                },
                                {
                                  title: 'Training Needs',
                                  dataIndex: 'trainingNeeds',
                                  key: 'trainingNeeds',
                                  width: '65%',
                                  render: (text) => (
                                    <Typography.Paragraph style={{ margin: 0 }}>
                                      {text}
                                    </Typography.Paragraph>
                                  ),
                                },
                                {
                                  title: 'Evidence',
                                  dataIndex: 'calls',
                                  key: 'calls',
                                  width: '20%',
                                  render: (calls: TrainingEvidence[]) => (
                                    <Typography.Text>
                                      {calls.length} call{calls.length !== 1 ? 's' : ''} identified
                                    </Typography.Text>
                                  ),
                                },
                              ]}
                            />
                          </div>
                        );
                      },
                    }}
                    columns={[
                      {
                        title: 'Agent ID',
                        dataIndex: 'aId',
                        key: 'aId',
                        width: '25%',
                        render: (text: string, record: CombinedAgentData) => (
                          <Link to={`/agent?agentId=${record.agentId}`}>{text}</Link>
                        ),
                      },
                      {
                        title: 'Section Score',
                        dataIndex: 'sectionScore',
                        key: 'sectionScore',
                        width: '25%',
                        render: (score?: number) => (score ? `${score.toFixed(2)}%` : '-'),
                      },
                      {
                        title: 'Overall Score',
                        dataIndex: 'overallScore',
                        key: 'overallScore',
                        width: '25%',
                        render: (score?: number) => (score ? `${score.toFixed(2)}%` : '-'),
                      },
                      {
                        title: 'Training Needs',
                        key: 'trainingNeeds',
                        width: '25%',
                        render: (_, record: CombinedAgentData) => {
                          const isExpanded = expandedRowKey === `${section}-${record.aId}`;
                          return (
                            <Button
                              type={isExpanded ? 'primary' : 'default'}
                              onClick={(e) => {
                                e.stopPropagation();
                                setExpandedRowKey(isExpanded ? null : `${section}-${record.aId}`);
                              }}
                              icon={isExpanded ? <UpOutlined /> : <DownOutlined />}
                            >
                              {isExpanded ? 'Hide Details' : 'View Details'}
                            </Button>
                          );
                        },
                      },
                    ]}
                    pagination={{ pageSize: 5 }}
                  />
                ),
            }))}
          />
        </Card>
      </Col>
    </Row>
  );
};

const AgentDashboard: React.FC = () => {
  const [metrics, setMetrics] = useState<AgentMetrics | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isDownloading, setIsDownloading] = useState(false);
  const { teamId } = useSelector((state: RootState) => state.persisted.user.value);
  const isCompleteTeam = COMPLETE_TEAMS.includes(teamId);

  useEffect(() => {
    getAgentDashboardMetrics().then((data) => {
      setMetrics(data);
      setIsLoading(false);
    });
  }, []);

  if (isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', padding: '50px' }}>
        <Spin size="large" />
      </div>
    );
  }

  if (!metrics) {
    return (
      <div style={{ textAlign: 'center', padding: '50px' }}>
        <Typography.Text type="secondary">No data available</Typography.Text>
      </div>
    );
  }

  // Prepare data for section-wise bar chart
  const sectionScores = Object.entries(metrics.currentMonthSectionMetrics || {}).map(
    ([name, data]) => ({
      name,
      score: data.averageScore,
    })
  );

  const topPerformerColumns: ColumnsType<TopPerformingAgent> = [
    {
      title: 'Agent ID',
      dataIndex: 'aId',
      key: 'aId',
      render: (text: string, record: TopPerformingAgent) => (
        <Link to={`/agent?agentId=${record.agentId}`}>{text}</Link>
      ),
    },
    {
      title: 'Score',
      dataIndex: 'score',
      key: 'score',
      render: (score: number) => `${score.toFixed(2)}%`,
    },
  ];

  // Add these columns for the underperforming agents table
  const underperformingColumns: ColumnsType<UnderperformingAgent> = [
    {
      title: 'Agent ID',
      dataIndex: 'aId',
      key: 'aId',
      render: (text: string, record: UnderperformingAgent) => (
        <Link to={`/agent?agentId=${record.agentId}`}>{text}</Link>
      ),
    },
    {
      title: 'Section Score',
      dataIndex: 'sectionScore',
      key: 'sectionScore',
      render: (score: number) => `${score.toFixed(2)}%`,
    },
    {
      title: 'Overall Score',
      dataIndex: 'overallScore',
      key: 'overallScore',
      render: (score: number) => `${score.toFixed(2)}%`,
    },
  ];

  const topPtpPerformerColumns: ColumnsType<{
    aId: string;
    ptpPercentage: number;
    agentId: string;
  }> = [
    {
      title: 'Agent ID',
      dataIndex: 'aId',
      key: 'aId',
      render: (text: string, record: { agentId: string }) => (
        <Link to={`/agent?agentId=${record.agentId}`}>{text}</Link>
      ),
    },
    {
      title: 'PTP Rate',
      dataIndex: 'ptpPercentage',
      key: 'ptpPercentage',
      render: (percentage: number) => `${percentage}%`,
    },
  ];

  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload[0]) {
      const currentValue = payload[0].value;
      const sectionName = payload[0].payload.name;
      const lastMonthValue = metrics?.lastMonthSectionMetrics[sectionName]?.averageScore || 0;

      const percentageChange =
        lastMonthValue === 0
          ? 100 // If last month was 0, show 100% increase
          : ((currentValue - lastMonthValue) / lastMonthValue) * 100;

      return (
        <div
          style={{
            backgroundColor: 'white',
            padding: '10px',
            border: '1px solid #ccc',
            borderRadius: '4px',
          }}
        >
          <p>
            <strong>{sectionName}</strong>
          </p>
          <p>Current: {currentValue.toFixed(2)}%</p>
          <p>Last Month: {lastMonthValue.toFixed(2)}%</p>
          <p
            style={{
              color: percentageChange >= 0 ? '#52c41a' : '#f5222d',
            }}
          >
            {percentageChange >= 0 ? '↑' : '↓'} {Math.abs(percentageChange).toFixed(1)}%
          </p>
        </div>
      );
    }
    return null;
  };

  const handleExportAgentsNeedingTrainingReport = async () => {
    try {
      setIsDownloading(true);
      const { url } = await exportAgentsNeedingTrainingReport();
      const link = document.createElement('a');
      link.href = url;
      link.download = '';
      link.click();
    } catch (error) {
      console.error('Error exporting report:', error);
    } finally {
      setIsDownloading(false);
    }
  };

  // Get sections from underperforming agents
  const underperformingSections = Object.keys(metrics.underperformingAgentsBySection || {});

  return (
    <div className="agent-dashboard">
      <div style={{ marginBottom: '24px' }}>
        <Title level={2}>Agent Performance Dashboard</Title>
        <Row align="middle" gutter={8}>
          <Col>
            <Typography.Text type="secondary" style={{ fontSize: '16px' }}>
              Showing performance metrics for{' '}
              {new Date().toLocaleString('default', { month: 'long', year: 'numeric' })}
            </Typography.Text>
          </Col>
          <Col>
            <Tooltip title="Metrics are calculated based on calls evaluated in the current month">
              <InfoCircleOutlined style={{ color: '#8c8c8c' }} />
            </Tooltip>
          </Col>
        </Row>
      </div>

      {/* Key Metrics Row */}
      <Row gutter={[16, 16]}>
        <Col span={6}>
          <Link to={RoutePath.ALL_CALLS}>
            <Card>
              <Statistic
                title={
                  <span>
                    Total Calls This Month{' '}
                    <Tooltip title="Number of calls evaluated in current month">
                      <InfoCircleOutlined style={{ fontSize: '14px', color: '#8c8c8c' }} />
                    </Tooltip>
                  </span>
                }
                value={metrics.totalCalls}
              />
            </Card>
          </Link>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic
              title={
                <span>
                  Current Team Score{' '}
                  <Tooltip title="Average team performance score for current month">
                    <InfoCircleOutlined style={{ fontSize: '14px', color: '#8c8c8c' }} />
                  </Tooltip>
                </span>
              }
              value={metrics.overallTeamScore}
              precision={2}
              suffix="%"
              valueStyle={{ color: metrics.overallTeamScore >= 75 ? '#3f8600' : '#cf1322' }}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic
              title={
                <span>
                  Average Call Duration{' '}
                  <Tooltip title="Average duration of calls this month">
                    <InfoCircleOutlined style={{ fontSize: '14px', color: '#8c8c8c' }} />
                  </Tooltip>
                </span>
              }
              value={Math.floor(metrics.averageCallDuration / 60)}
              suffix={`m ${Math.round(metrics.averageCallDuration % 60)}s`}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Link to={RoutePath.COMPLIANCE_DASHBOARD}>
            <Card>
              <Statistic
                title={
                  <span>
                    Compliance Rate{' '}
                    <Tooltip title="Percentage of compliant calls this month">
                      <InfoCircleOutlined style={{ fontSize: '14px', color: '#8c8c8c' }} />
                    </Tooltip>
                  </span>
                }
                value={metrics.complianceRate}
                suffix="%"
                valueStyle={{ color: metrics.complianceRate >= 90 ? '#3f8600' : '#cf1322' }}
              />
            </Card>
          </Link>
        </Col>
      </Row>

      {/* Replace both separate sections with the combined component */}
      <AgentPerformanceAnalysis
        underperformingAgents={metrics.underperformingAgentsBySection}
        teamTni={metrics.teamTni}
      />

      {/* Charts Row */}
      <Row gutter={[16, 16]} style={{ marginTop: '24px' }}>
        <Col span={isCompleteTeam ? 12 : 18}>
          <Card title="Section-wise Performance">
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={sectionScores} margin={{ top: 20, right: 30, left: 20, bottom: 30 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="name"
                  height={40}
                  angle={!isCompleteTeam ? 0 : -45}
                  textAnchor={!isCompleteTeam ? 'middle' : 'end'}
                  interval={0}
                  tickFormatter={(value) => {
                    if (!isCompleteTeam) return value;
                    const MAX_LENGTH = 10;
                    if (value.length > MAX_LENGTH) {
                      return value.substring(0, MAX_LENGTH) + '...';
                    }
                    return value;
                  }}
                />
                <YAxis domain={[0, 100]} />
                <RechartsTooltip content={<CustomTooltip />} />
                <Legend wrapperStyle={{ marginTop: -15 }} />
                <Bar
                  dataKey="score"
                  fill="#1890ff"
                  name="Score (%)"
                  label={{
                    position: 'top',
                    formatter: (value: number) => `${Number(value).toFixed(2)}%`,
                  }}
                />
              </BarChart>
            </ResponsiveContainer>
          </Card>
        </Col>
        <Col span={isCompleteTeam ? 6 : 6}>
          <Card title="Top Performing Agents" className="top-performers-card">
            <Table
              dataSource={metrics.topPerformingAgents}
              columns={topPerformerColumns}
              pagination={false}
              size="small"
              scroll={{ y: 300 }}
            />
          </Card>
        </Col>
        {isCompleteTeam && (
          <Col span={6}>
            <Card title="Top PTP Performers" className="top-performers-card">
              <Table
                dataSource={metrics.topPtpPerformers}
                columns={topPtpPerformerColumns}
                pagination={false}
                size="small"
                scroll={{ y: 300 }}
              />
            </Card>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default AgentDashboard;
