import { callApi } from '.';

export const getCollectionDashboardMetrics = async () => {
  return await callApi({
    method: 'GET',
    url: '/dashboard/collection-metrics',
    serializerFunc: (response: any) => response.data,
  });
};

export const getAgentDashboardMetrics = async () => {
  return await callApi({
    method: 'GET',
    url: '/dashboard/agent-metrics',
    serializerFunc: (response: any) => response.data,
  });
};

export const getComplianceMetrics = async () => {
  return await callApi({
    method: 'GET',
    url: '/dashboard/compliance-metrics',
    serializerFunc: (response: any) => response.data,
  });
};
